// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-image-tsx": () => import("./../../../src/pages/image.tsx" /* webpackChunkName: "component---src-pages-image-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-articles-list-tsx": () => import("./../../../src/templates/articles-list.tsx" /* webpackChunkName: "component---src-templates-articles-list-tsx" */),
  "component---src-templates-artist-artist-covers-tsx": () => import("./../../../src/templates/artist/artist-covers.tsx" /* webpackChunkName: "component---src-templates-artist-artist-covers-tsx" */),
  "component---src-templates-artist-artist-songs-tsx": () => import("./../../../src/templates/artist/artist-songs.tsx" /* webpackChunkName: "component---src-templates-artist-artist-songs-tsx" */),
  "component---src-templates-artists-list-tsx": () => import("./../../../src/templates/artists-list.tsx" /* webpackChunkName: "component---src-templates-artists-list-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-song-tsx": () => import("./../../../src/templates/song.tsx" /* webpackChunkName: "component---src-templates-song-tsx" */),
  "component---src-templates-songs-list-tsx": () => import("./../../../src/templates/songs-list.tsx" /* webpackChunkName: "component---src-templates-songs-list-tsx" */),
  "component---src-templates-tag-page-tsx": () => import("./../../../src/templates/tag-page.tsx" /* webpackChunkName: "component---src-templates-tag-page-tsx" */),
  "component---src-templates-user-user-articles-tsx": () => import("./../../../src/templates/user/user-articles.tsx" /* webpackChunkName: "component---src-templates-user-user-articles-tsx" */),
  "component---src-templates-user-user-translations-tsx": () => import("./../../../src/templates/user/user-translations.tsx" /* webpackChunkName: "component---src-templates-user-user-translations-tsx" */),
  "component---src-templates-users-list-tsx": () => import("./../../../src/templates/users-list.tsx" /* webpackChunkName: "component---src-templates-users-list-tsx" */),
  "component---src-templates-wip-article-tsx": () => import("./../../../src/templates/wip-article.tsx" /* webpackChunkName: "component---src-templates-wip-article-tsx" */),
  "component---src-templates-wip-articles-list-tsx": () => import("./../../../src/templates/wip-articles-list.tsx" /* webpackChunkName: "component---src-templates-wip-articles-list-tsx" */)
}

